* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.aboutme,
.mywork   {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.aboutme {
  /* background-image: url('/images/img-2.jpg'); */
  background: rgb(189, 54, 54);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.main-container >h1 {
  padding-top: 30px;


}
.mywork {
  /* background-image: url('/images/img-1.jpg'); */
  background: rgb(45, 16, 112);
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.main-banner {
background: #fff;
width: 80%;
height: 65%;
border-radius: 10px;
display: flex;
}

.banner-left {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
 width: 40%;
 height: 100%;
 border-radius: 10px;
 position: relative;

}
.banner-left-img{
border-radius: 50%;
position: absolute; 
background-image: url('./components/images/profile.jpg');
 background-repeat: no-repeat;
 background-position: center;
 background-size: cover;
 width: 80%;
 height: 80%;
  
}
.banner-right {
background: white;
width: 60%;
height: 100%;
text-align: center;

border-radius: 10px;
position: relative

}
.banner-right p{
 margin: 20px;
 position: absolute;               
 top: 50%;                         
 transform: translate(0, -50%);
 line-height: 1.5;
 text-align: justify;
 padding: 15px;
}
.profile-img {
width: 60%;
height: 50%;

}
@media screen and (max-width: 1100px){
  .main-banner {
    background: #fff;
    width: 80%;
    height: 65%;
    /* width: 400px;
    height: 550px; */
    border-radius: 10px;
    display: flex;
    }

  .banner-left-img{
        width: 85%;
     
    }

  .banner-right {
    background: #fff;
    width: 60%;
    height: 100%;
    /* width: 400px;
    height: 550px; */
    text-align: center;
    border-radius: 10px;
         
   }
   .banner-right p{
    padding: 5px;
    font-size: 0.8rem;
  }
}
.banner-left-img{
  border-radius: 55%;
  position: absolute; 
  background-image: url('./components/images/profile.jpg');
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   width: 80%;
   height: 80%;
    
  }


 @media screen and (max-width: 768px) {
  .main-banner {
    background: #fff;
    width: 400px;
    height: 410px;
    border-radius: 10px;
    display: grid;
   }
         .banner-right {
        background: white;
        width: 400px;
        height: 410px;
        text-align: center;
        border-radius: 10px;
             
       }


       .banner-right p{
        padding: 10px;
      }
    
}