video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
.main-container {

    /* background: url('./images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgb(0,0,0,0.2);
    object-fit: contain;
}

.main-container >h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;

}
.main-container >p{
    margin-top: 10px;
    color: #fff;
    font-size: 40px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}


.main-card1 {
    background: rgb(190, 28, 28);

}
.main-card2 {
    background: rgb(33, 34, 122);

}
@media screen and (max-width: 960px){
    .main-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

}

@media screen and (max-width: 768px) {
    .main-container > video {
        /* display: none; */
        background-image: url('../components/images/image-13.jpg');
         /* background: url('./images/img-home.jpg') center center/cover no-repeat; */
         z-index: -1;
    }
    .main-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }
    .main-container >p {
        font-size: 30px;
        
    }
    
}