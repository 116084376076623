.container {
    padding: 4rem;
    background: #fff;
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  h1 {
    text-align: center;
    padding-bottom: 20px;
  }
  
 .banner-armotor {
  width: 800px;
  height: 500px;
  background: #fff;
  background-image: url(./../images/img-work-1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
    
    
 }

 .banner-newway {
  width: 800px;
  height: 500px;
  background: #fff;
  background-image: url(./../images/img-work-2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
 }
 
 .banner-coffeeshop{
  width: 800px;
  height: 500px;
  background: #fff;
  background-image: url(./../images/img-work-3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
 }
 

 .banner-ecoresort{
  width: 800px;
  height: 500px;
  background: #fff;
  background-image: url(./../images/img-work-4.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
 }
 
 .banner-ammygeorge {
  width: 800px;
  height: 500px;
  background: #fff;
  background-image: url(./../images/img-work-5.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
 }
 .text{
  width: 800px;
  height: 100%;
  background-color: #fff;
  padding-top: 30px;
  line-height: 1.5;
  text-align: justify;
 }
 
 .banner-virtualbank {
  width: 800px;
  height: 500px;
  background: #fff;
  background-image: url(./../images/virtualbank5.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
 }

 @media screen and (max-width: 960px) {
  .banner-armotor {
    width: 600px;
    height: 400px;
   
  }
  .banner-newway {
    width: 600px;
    height: 400px;
     }
 .banner-coffeeshop {
     width: 600px;
    height: 400px;
     }
     .banner-coffeeshop2 {
      width: 600px;
     height: 400px;
      }

 .banner-ecoresort {
      width: 600px;
      height: 400px;
       }

.banner-ammygeorge {
 width: 600px;
 height: 400px;
         }

 
      .text {
        width: 600px;
        height: 100%;
      }
  
 }

 @media screen and (max-width: 768px) {
  .banner-armotor {
    width: 400px;
    height: 250px;
   
  }
  .banner-newway {
    width: 400px;
    height: 250px;
     }
 .banner-coffeeshop {
  width: 400px;
  height: 250px;
     }

 .banner-ecoresort {
  width: 400px;
  height: 250px;
       }

.banner-ammygeorge {
  width: 400px;
  height: 250px;
         }
         
.banner-virtualbank {
width: 400px;
height: 250px;
                 }


  .text{
    width: 400px;
    height: 100%;
  }
  
}

